import { axiosInstance } from './axios';

const onLoad = () => {
  const reorderable = document.querySelectorAll('.reorderable');
  reorderable.forEach((element) => {
    const tbody = element.querySelector('tbody');
    const rows = tbody.querySelectorAll('tr');
    rows.forEach((row) => {
      const firstCell = row.querySelector('td:first-child');
      firstCell.innerHTML = '';

      const up = document.createElement('span');
      up.classList.add('fa-solid', 'fa-arrow-up', 'px-2', 'cursor-pointer');
      firstCell.append(up);

      up.addEventListener('click', async () => {
        if (element.classList.contains('reordering')) return;
        const upperRow = row.previousElementSibling;

        if (!upperRow) return;

        const url = row.dataset.reorderUrl;
        element.classList.add('reordering');
        await axiosInstance.post(url, { direction: 'up' });

        tbody.insertBefore(row, upperRow);
        element.classList.remove('reordering');
      });

      const down = document.createElement('span');
      down.classList.add('fa-solid', 'fa-arrow-down', 'px-2', 'cursor-pointer');
      firstCell.append(down);

      down.addEventListener('click', async () => {
        if (element.classList.contains('reordering')) return;
        const lowerRow = row.nextElementSibling;

        if (!lowerRow) return;

        const url = row.dataset.reorderUrl;
        element.classList.add('reordering');
        await axiosInstance.post(url, { direction: 'down' });

        tbody.insertBefore(row, lowerRow.nextSibling);
        element.classList.remove('reordering');
      });
    });
  });
};

document.documentElement.addEventListener('turbo:load', onLoad);
document.documentElement.addEventListener('turbo:render', onLoad);
