import axios from 'axios';

function getCSRFToken() {
  return document.querySelector('meta[name=csrf-token]')?.content;
}

export const axiosInstance = axios.create({
  headers: {
    'X-CSRF-Token': getCSRFToken(),
  },
});
