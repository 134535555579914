document.documentElement.addEventListener('click', (event) => {
  if (!event.target.classList.contains('navigation-toggle')) return;
  const nav = document.getElementById('navigation');

  if (event.target.dataset.open === 'true') {
    nav.style.maxHeight = '0px';
    event.target.dataset.open = 'false';
    return;
  }

  nav.style.maxHeight = '1000px';
  event.target.dataset.open = 'true';
});
