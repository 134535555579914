import { axiosInstance } from './axios';

document.documentElement.addEventListener('trix-file-accept', function (event) {
  const { file } = event;

  if (!file.type.match(/^image\/.*/)) {
    alert('You can only upload images.');
    event.preventDefault();
  }
});

document.documentElement.addEventListener(
  'trix-attachment-add',
  async (event) => {
    if (!event.attachment.file) return;

    try {
      const formData = new FormData();
      formData.append('image[file]', event.attachment.file);
      formData.append('image[type]', 'post');

      const { data } = await axiosInstance.post('/admin/images', formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (e) => {
          const progress = (e.loaded / e.total) * 100;
          event.attachment.setUploadProgress(progress);
        },
      });

      event.attachment.setAttributes({
        url: data.url,
        href: data.url,
      });
    } catch (error) {
      event.attachment.remove();
      alert('There was an error uploading your image. Please try again.');
      throw error;
    }
  }
);
