document.documentElement.addEventListener('turbo:load', () => {
  const carousels = document.querySelectorAll('.carousel');

  for (const carousel of carousels) {
    new Carousel(carousel).init();
  }
});

class Carousel {
  constructor(element) {
    this.element = element;
    this.currentIndex = 0;
  }

  init() {
    const images = this.element.querySelectorAll('img');
    images.forEach((img, index) => {
      img.style.transform = `translateX(${(index - this.currentIndex) * 100}%)`;
    });
    this.imageCount = this.element.querySelectorAll('img').length;
    this.interval = setInterval(
      () => this.moveSlides(),
      +this.element.dataset.interval
    );
  }

  moveSlides() {
    const images = this.element.querySelectorAll('img');
    images.forEach((img, index) => {
      img.style.transform = `translateX(${(index - 1) * 100}%)`;
      if (index > 0) return;
      img.addEventListener(
        'transitionend',
        () => {
          img.remove();
          img.style.transform = `translateX(${(this.imageCount - 1) * 100}%)`;
          this.element.appendChild(img);
        },
        { once: true }
      );
    });
  }
}
